import React, { useEffect, useState } from "react";
import axios from "axios";
import { YotpoResponse, YotpoReview } from "@/pages/api/yotpo";
import FiveStars from "../FiveStars";
import Image from "next/image";

import ThumbUp from "@/public/icons/thumb-up-green.svg";
import { ContentfulTextContainer } from "../ContentfulTextContainer";

enum ORDER {
  DESC = "desc",
  ASC = "asc",
}

const Review = ({ review }: { review: YotpoReview }) => {
  const { name, title, content, updated_at, score, } = review;
  return (
    <div className="flex flex-col gap-7 pt-6 border-t border-alter-black-10 md:flex-row">
      <div className="space-y-2 basis-1/3">
        <div>{name}</div>
        <div className="text-sm text-alter-black-60">
          Verified Owner
        </div>
        {score > 3.5 &&
          <div className="flex gap-2 text-sm text-alter-black-60">
            <Image src={ThumbUp} alt="thumbs up" width={18} height={16} />
            I recommend this product
          </div>
        }
      </div>
      <div className="flex flex-col gap-3 basis-2/3">
        <div className="flex justify-between">
          <FiveStars score={score} />
          <span className="text-sm text-alter-black-60">{new Date(updated_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
        </div>

        {title && <ContentfulTextContainer markdown={title} className="mt-1 -mb-6" />}
        <ContentfulTextContainer markdown={content} className="mt-1 font-light" />
      </div>
    </div>
  )
};

export const YotpoReviews = () => {
  const [data, setData] = useState<YotpoResponse | null>(null);
  const [order, setOrder] = useState<ORDER>(ORDER.DESC);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 6;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDebouncedSearch(e.target.value);
  };

  const handleOrderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOrder(e.target.value as ORDER);
  }

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1))
  }

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages))
  }

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get("/api/yotpo");
        setData(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    fetchReviews();
  }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearch(debouncedSearch);
    }, 250);

    return () => {
      clearTimeout(debounce);
    }
  }, [search, debouncedSearch]);

  const filteredAndSortedReviews = React.useMemo(() => {
    if (!data) return [];
    return data.reviews
      .filter(review => review.content.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) => {
        if (order === ORDER.DESC) {
          return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
        } else {
          return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
        }
      });
  }, [data, search, order]);

  const currentReviews = filteredAndSortedReviews.slice(
    (currentPage - 1) * reviewsPerPage,
    currentPage * reviewsPerPage
  );

  const totalPages = Math.ceil(filteredAndSortedReviews.length / reviewsPerPage);
  const percentRecommend = React.useMemo(() => {
    if (!data) return null;
    const numRecommend = data.reviews.reduce((acc, review) => review.score > 3 ? acc + 1 : acc, 0);
    return Math.floor(numRecommend / data.total_reviews * 1000) / 10;
  }, [data]);

  return (
    <section id="yotpo-reviews" className="py-16 px-4 rounded-b-3xl md:rounded-none bg-white md:px-20">
      <div className="width-1440 text-alter-black">
        <div className="text-sm text-alter-black-60 uppercase">Customer Reviews</div>
        <h3 className="mt-4 text-[24px] md:mt-6 md:text-[40px]">What Alter members are saying</h3>
        <div className="flex items-center gap-[10px] mt-7 md:mt-9">
          <span className="text-[32px]">{data?.average_score}</span>
          <FiveStars score={data?.average_score} />
        </div>
        <div className="mt-2 text-alter-black-60 text-xs font-light md:text-sm">Based on {data?.total_reviews} reviews | {percentRecommend}% would recommend this product</div>
        <div className="flex flex-col gap-5 mt-6 md:flex-row md:justify-between md:items-center">
          <div className="flex items-center  text-sm font-light">
            <span>Sort:</span>
            <select className="ml-3 p-1 border border-alter-black-10 rounded-sm text-alter-black-60" value={order} onChange={handleOrderChange}>
              <option value={ORDER.DESC}>Most recent</option>
              <option value={ORDER.ASC}>Oldest first</option>
            </select>
            <div className="flex items-center gap-2 ml-4 border-b border-b-alter-black-10">
              <Image src="/icons/magnifying-glass.svg" alt="search" width={14} height={14} />
              <input className="p-1 pl-3" placeholder="Search reviews..." value={debouncedSearch} onChange={handleSearch} />
            </div>
          </div>
          <div className="text-alter-black-60 font-light">Displaying Reviews {currentPage * reviewsPerPage - reviewsPerPage + 1} - {currentPage * reviewsPerPage} of {data?.total_reviews} results</div>
        </div>
        <div className="mt-6">
          {currentReviews.map(review => (
            <Review key={review.id} review={review} />
          ))}
        </div>
        <div className="mt-6 flex justify-center gap-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="disabled:opacity-50"
            aria-label="Previous"
          >
            <Image src="/icons/chev-right.svg" alt="Previous" width={12} height={12} className="rotate-180" />
          </button>
          <span>{currentPage} of {totalPages}</span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="disabled:opacity-50"
            aria-label="Next"
          >
            <Image src="/icons/chev-right.svg" alt="Next" width={12} height={12} />

          </button>
        </div>
      </div>
    </section>
  )
}

export const YotpoStars = () => {
  const [data, setData] = useState<YotpoResponse | null>(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get("/api/yotpo");
        setData(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    fetchReviews();
  }, []);

  return (
    <FiveStars score={data?.average_score} />
  )
}

// ==============================

export const YotpoProductId = {
  swabKit: "7396533633201",
} as const;

export interface ProductReviewsBottomLine {
  average_score: number;
  total_reviews: number;
}

/**
 * Fetches review metadata from Yotpo.
 * @param productId The Yotpo product ID for which to fetch review metadata.
 * @returns The review metadata.
 */
export const fetchReviewsMetadata = async (
  productId: string
): Promise<ProductReviewsBottomLine | null> => {
  try {
    const appId = process.env.NEXT_PUBLIC_YOTPO_APP_ID;
    const fetchURL = `https://api.yotpo.com/products/${appId}/${productId}/bottomline`;
    const response = await fetch(fetchURL);
    const data = await response.json();
    return (data?.response?.bottomline as ProductReviewsBottomLine) ?? null;
  } catch (error) {
    console.error("Failed to fetch Yotpo reviews metadata:", error);
    return null;
  }
};
